<template>
  <div class="w-100 h-100">
    <section
      id="chatbot-body"
      ref="refChatLogPS"
      class="ps-container position-relative scroll-area ps chatbot"
      style="background-repeat:no-repeat; background-size: 100%;"
      :style="{ 'background-image': `url(${require(`@/assets/images/chatbot/${activeImage}`)})`}"
    >
      <div class="position-absolute setting-icon"
           draggable="false"
           style="right:10px; top:10px; display:none;"
      >
        <feather-icon
          icon="SettingsIcon"
          size="21"
          class="text-primary cursor-pointer"
          @click.stop="openChatbotSetting"
        />
      </div>
      <div class="ps__rail-x"
           style="left: 0px; bottom: 0px"
      >
        <div class="ps__thumb-x"
             tabindex="0"
             style="left: 0px; width: 0px"
        />
      </div>
      <div class="ps__rail-y"
           style="top: 0px; right: 0px"
      >
        <div class="ps__thumb-y"
             tabindex="0"
             style="top: 0px; height: 0px"
        />
      </div>
    </section>
    <chat-footer
      :is-processing="isProcessing"
      :is-playing="isPlaying"
      @sendMessage="sendMessage"
    />
    <b-modal
      id="bot-setting-modal"
      title="Chatbot Settings"
      hide-footer
    >
      <bot-setting />
    </b-modal>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { BOT_OBJ, IDLE, SPEAKING } from '@/const/chatbot'
import PlayBase64Audio from '@/utils/base64-audio-player'
import useJwt from '@/auth/jwt/useJwt'
import { getDemoBotResponse } from '@/auth/jwt/useDatablock'
import ChatFooter from './ChatFooter.vue'
import BotSetting from './BotSetting.vue'

const synth = window.speechSynthesis
export default {
  components: {
    ChatFooter,
    BotSetting,
  },
  props: {
    userName: {
      type: String,
      required: true,
    },
    lessonInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      botURl: 'https://president-assitant.vercel.app/abc',
      isProcessing: false,
      speechSynthesisUtterance: null,
      isPlaying: false,
      messages: [],
      dataBlockSession: null,
    }
  },
  computed: {
    activeImage() {
      return this.isPlaying ? SPEAKING[this.gender] : IDLE[this.gender]
    },
    getTemplate() {
      const { topic, question, answer } = this.lessonInfo

      return `My name is ${this.userName}. If you don't know my name, ask me to provide my name. The lesson name is ${topic || 'not defined, so you should ask me to provide a lesson name'}.\n\n
      
      You are a chatbot teacher. ${topic ? `You are tutoring me about ${topic}. \n\n
      
      You are to converse with me about this questions and answer set ${question} + ${answer}. You are to help me with any questions I may have about ${topic} through Socratic, dialectic teaching in a tactful manner.\n\n
      
      Only give the answer to the question related to ${topic}. If I ask any questions outside of the ${topic}, tell me that I should stick with the current lesson and remind me what the lesson is. \n\n
      
      When the conversation starts, do not answer previous questions.\n
      No need to answer questions present in chat history.` : 'The lesson name is not defined so, ask for a lesson name'}.\n\n
      
      chat_history: {chat_history}\n
      Human: {human_input}\n
      Chatbot:`
    },
    gender() {
      return this.$store.getters['appConfig/currentChatbotCharacter']
    },
  },
  created() {
    setTimeout(() => {
      this.initializeSpeech()
    }, 2000)
    window.onbeforeunload = () => {
      this.stop()
    }
  },
  beforeDestroy() {
    this.stop()
  },
  methods: {
    initializeSpeech() {
      this.stop()
      const speechSynthesisUtterance = new SpeechSynthesisUtterance()
      speechSynthesisUtterance.lang = 'en-US'
      this.speechSynthesisUtterance = speechSynthesisUtterance
      speechSynthesisUtterance.addEventListener('end', () => {
        this.isPlaying = false
      })
    },
    pause() {
      if (synth?.speaking) {
        synth.pause()
        this.isPlaying = false
      }
    },
    stop() {
      if (synth?.speaking) {
        synth.cancel()
      }
    },
    getBase64FromTextAndPlay(message) {
      useJwt.convertTextToBase64({
        text: message,
        gender: this.gender?.includes('female') ? 'female' : 'male',
      }).then(response => {
        this.play(response.data.audio_file)
      })
    },
    play(base64) {
      this.isPlaying = true
      PlayBase64Audio(base64, () => {
        this.isPlaying = false
      })
    },
    chatParameter(msg) {
      const chatHistory = this.messages.map(item => {
        if (item.from === 'bot') {
          return {
            chatbot: item.message,
          }
        }
        return {
          human: item.message,
        }
      })
      return {
        bot: 'Chatbot',
        chat_history: chatHistory,
        human_input: msg,
        prompt: this.getTemplate,
      }
    },
    sendMessage(msg) {
      this.messages.push({
        from: 'User',
        message: msg,
        avatar: null,
      })
      this.isProcessing = true

      const data = {
        ...this.chatParameter(msg),
        ...this.lessonInfo,
        userName: this.userName,
      }
      getDemoBotResponse(data, {
        headers: {
          ...(this.dataBlockSession ? {
            'x-session': this.dataBlockSession,
          } : {}),
        },

      }).then(response => {
        const responseMessage = response.data.message
        this.messages.push({
          from: 'bot',
          message: responseMessage,
          avatar: BOT_OBJ.avatar,
        })
        this.dataBlockSession = response.data['x-session']
        this.getBase64FromTextAndPlay(responseMessage)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    openChatbotSetting() {
      this.$bvModal.show('bot-setting-modal')
    },
  },
}
</script>
<style lang="scss">
  .chatbot {
    height: 150px;
    width: 150px;
    margin:auto;

    &:hover{
      .setting-icon {
        display: block !important;
      }
    }
    /* overflow: scroll !important; */
  }
</style>
